<template>
  <el-row :gutter="20" >
      <el-col :span="8" >
          <el-card :style="{maxHeight: pageH + 'px'}" style="overflow-y: scroll">
              <div class="flex-between mb20">
                  <h4>资源列表</h4>
                  <el-link @click="saveDialogShow(1)" type="primary">添加</el-link>
              </div>
              <el-tree
                      v-loading="treeLoading"
                      :data="resourceList"
                      node-key="id"
                      :default-expand-all="true"
                      :highlight-current="true"
                      @node-click="nodeClick"
                      :props="defaultProps">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                  <span>
                  <el-button
                          type="text"
                          size="mini"
                          class="mr5 ml10"
                          @click.stop="saveDialogShow(2,node)">
                    添加
                  </el-button>
                       <el-button
                               type="text"
                               size="mini"
                               class="mr5"
                               @click.stop="saveDialogShow(3,node)">
                    编辑
                  </el-button>
                  <el-button
                          type="text"
                          size="mini"
                          @click.stop="remove(node, data)">
                    删除
                  </el-button>
                </span></span>

              </el-tree>
          </el-card>
      </el-col>
      <el-col :span="16">
          <el-card>
              <div class="flex-between mb20">
                  <h4>接口列表</h4>
                  <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-plus"
                          :disabled="activeNodeId == ''"
                          @click="apiDialogVisible = true"
                  >
                      关联接口
                  </el-button>
              </div>
              <el-table :data="apiList">
                  <el-table-column
                          prop="path"
                          label="接口名称"
                  />
                  <el-table-column
                          label="操作"
                          width="140px"
                  >
                      <template slot-scope="{row}">
                          <el-link type="primary" @click="deletePerms(row)">
                              移除
                          </el-link>
                      </template>
                  </el-table-column>
              </el-table>
          </el-card>
      </el-col>
      <el-dialog
              title="添加资源"
              :visible.sync="dialogVisible"
              width="620px"
              @close="dialogClose"
              @open="dialogOpen"
      >
          <el-form
                  v-loading="loading"
                  v-if="dialogVisible"
                  ref="form"
                  :model="form"
                  class="assest-save-form"
                  label-width="100px"
          >
              <el-form-item label="菜单名称" prop="name" :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]">
                  <el-input v-model="form.name" maxlength="100"/>
              </el-form-item>
              <el-form-item label="菜单图标">
                  <el-input v-model="form.component" maxlength="100"/>
              </el-form-item>
              <el-form-item label="路由">
                  <el-input v-model="form.path" maxlength="100"/>
              </el-form-item>
              <el-form-item label="菜单类型" prop="type" :rules="[ { required: true, message: '菜单类型不能为空', trigger: 'change' }]">
                  <el-select v-model="form.type">
                      <el-option v-for="(item,index) in typeOptions" :key="index" :value="item.value" :label="item.label"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="状态">
                  <el-radio-group v-model="form.status">
                      <el-radio :label="0">启用</el-radio>
                      <el-radio :label="1">禁用</el-radio>
                  </el-radio-group>
              </el-form-item>
          </el-form>
          <div
                  slot="footer"
                  class="dialog-footer"
          >
              <el-button @click="dialogVisible = false">
                  取 消
              </el-button>
              <el-button
                      type="primary"
                      :disabled="loading"
                      @click="submitForm"
              >
                  提 交
              </el-button>
          </div>
      </el-dialog>
      <el-dialog
              title="添加接口"
              :visible.sync="apiDialogVisible"
              width="620px"
              @open="apiDialogOpen"
              @close="apiDialogClose"
              class="apiForm-dialog"
      >
          <el-form
                  v-loading="apiFormLoading"
                  v-if="apiDialogVisible"
                  ref="form"
                  :model="apiForm"
                  class="assest-save-form"
                  label-width="80px"
          >
              <el-form-item
                      v-for="(item,index) in apiForm.perms"
                      :key="index" label="接口路径"
                      :prop="'perms.' + index + '.path'"
                      :rules="{required: true, message: '请输入路径', trigger: 'change' }">
                  <el-input v-model="item.path"/>
                  <i v-if="index==0" class="el-icon-circle-plus-outline" style="position: absolute;top: 10px;right: -16px;cursor: pointer" @click="apiForm.perms.push({path: '',resource_id: ''})"/>
                  <i v-else class="el-icon-remove-outline" style="position: absolute;top: 10px;right: -16px;cursor: pointer" @click="apiForm.perms.splice(index,1)"/>
              </el-form-item>
          </el-form>
          <div
                  slot="footer"
                  class="dialog-footer"
          >
              <el-button @click="apiDialogVisible = false">
                  取 消
              </el-button>
              <el-button
                      type="primary"
                      @click="addResourcePerms"
              >
                  确 定
              </el-button>
          </div>
      </el-dialog>
  </el-row>
</template>
<script>
    import { saveOrUpdateResource, DeleteResourceByIds, ListResource,GetRoutePathList,ListResourcePerm, AddResourcePerms,DeletePerms } from '@/api/system/resource/resource'
    import { error, success, treeDataTranslate } from '@core/utils/utils'
    export default {
      data() {
        return {
          treeLoading: false,
          pageH: document.body.clientHeight - 150,
          apiLoading: false,
          apiFormLoading: false,
          tableData: [],
          apiForm: {
            perms:[
              {path: '', resource_id: ''}
            ]
          },
          apiDialogVisible: false,
          resourceList: [],
          loading: false,
          defaultProps: {
            children: 'children',
            label: 'name'
          },
          apiList: [],
          dialogVisible: false,
          typeOptions: [
            {label: '一级菜单',value:0},
            {label: '二级菜单',value:1},
            {label: '按钮',value:2}
          ],
          form: {
            component: '',
            path: '',
            name: '',
            status: 0,
            type: '',
            parentId: 0,
          },
          activeNodeId: '',
        }
      },
      created() {
        this.getResource()
      },
      methods: {
        deletePerms(row){
          DeletePerms({id: row.id}).then(res=>{
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.apiList.splice(row.index,1)
            } else {
              error(resData.msg)
            }
          })
        },
        addResourcePerms(){
          this.$refs.form.validate(valid=>{
            if(valid){
              this.apiFormLoading = true
              this.apiForm.perms.forEach(m=>{
                m.resource_id = this.activeNodeId
              })
              AddResourcePerms(this.apiForm.perms).then(res=>{
                this.apiFormLoading = false
                const resData = res.data
                if (resData.code === 0) {
                  this.apiDialogVisible = false

                  success(resData.msg)
                  this.listResourcePerm()
                } else {
                  error(resData.msg)
                }
              })
            }
          })

        },
        listResourcePerm(){
          ListResourcePerm(this.activeNodeId).then(res=>{
             this.apiList = res.data.data
          })
        },
        nodeClick(node){
          this.activeNodeId = node.id
          this.listResourcePerm()
        },
        apiDialogOpen(){
          // this.apiForm = {
          //   perms:[{path: '', resource_id: ''}]
          // }
          if(this.apiList.length === 0){
            this.apiForm = {
              perms:[{path: '', resource_id: ''}]
            }
          }else{
            this.apiForm = { perms: [] }
            this.apiList.forEach(a=>{
              this.apiForm.perms.push({
                path: a.path,
                resource_id: this.activeNodeId
              })
            })
          }

        },
        saveDialogShow(type,node){
          this.dialogVisible = true
          const nodeData = node.data
          if(type === 1) {
            this.form.parentId = 0
            // 添加子项
          }else if(type === 2){
            this.form.parentId = nodeData.id
          }else if(type === 3){
            // 编辑
            this.form.id = nodeData.id
            this.form.parentId = nodeData.parentId
            this.form.type = nodeData.type
            this.form.name = nodeData.name
            this.form.path = nodeData.path
            this.form.status = nodeData.status
            this.form.component = nodeData.component
          }
        },
        remove(node){
          this.$confirm('是否确认删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
          }).then(() => {
            const ids = []
            ids.push(node.data.id)
            DeleteResourceByIds({ ids }).then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getResource()
              } else {
                error(resData.msg)
              }
            })
          })
        },
        dialogClose(){
          this.form = {
            component: '',
            path: '',
            name: '',
            status: 0,
            type: '',
            parentId: 0,
          }
        },
        apiDialogClose(){
          this.apiForm = {
            perms:[
              {path: '', resource_id: ''}
            ]
          }
        },
        dialogOpen(){},
        getResource(){
          this.treeLoading = true
          ListResource().then(res=>{
            this.treeLoading = false
            const resData = res.data
            if (resData.code === 0) {
              this.resourceList = treeDataTranslate(resData.data)
               // this.resourceList = resData.data
            }
          })
        },
        submitForm(){
          this.$refs.form.validate(valid => {
            // eslint-disable-next-line no-empty
            if (valid) {
              this.loading = true
              saveOrUpdateResource(this.form).then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                  this.getResource()
                  this.dialogVisible = false
                } else {
                  error(resData.msg)
                }
              })
            } else {
              return false
            }
          })
        }
      }
    }

</script>
<style>
   .apiForm-dialog .el-dialog__body{
        padding: 20px 40px 20px 20px;
    }
</style>
